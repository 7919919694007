const config = {
  firebase: {
    apiKey: 'AIzaSyDohgvKHcCIrlBGFhEl6u3BkmZkk9YV-i0',
    authDomain: 'vocabulous-58d14.firebaseapp.com',
    projectId: 'vocabulous-58d14',
    storageBucket: 'vocabulous-58d14.appspot.com',
    messagingSenderId: '309991425905',
    appId: '1:309991425905:web:de63675239ef450aee18d0',
  },
  functions: {
    origin: 'https://europe-west1-vocabulous-58d14.cloudfunctions.net',
    region: 'europe-west1',
  },
  debug: true,
}

export default config
