import styled from 'styled-components'
import { theme } from 'styled-tools'
// import UserMenu from './UserMenu'

const StyledHeader = styled.header`
  position: relative;
  width: 100%;
  flex-shrink: 0;
  background-color: white;
  color: ${theme('colors.medium')};
  border-bottom: 1px solid ${theme('colors.border')};
  background: white;
  z-index: 10;
  a {
    color: currentColor;
  }
  height: 4rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0.6rem 1.2rem;
`

const Logo = ({ className }) => (
  <img className={className} alt="logo" src="/sciigo.svg" />
)

const StyledLogo = styled(Logo)`
  height: 2.5rem;
`

const Header = ({ siteTitle }) => {
  return (
    <StyledHeader>
      <StyledLogo />
      {/*<UserMenu />*/}
    </StyledHeader>
  )
}

export default Header
