import { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { Formik, Form } from 'formik'
import FormField from 'components/form/FormField'
import { getFirebase } from 'utils/firebase'
import Button from 'components/Button'
import { Page } from 'components/Layout'
import { CodeError } from 'utils/error'

const StyledForm = styled(Form)`
  min-width: 50vw;
  max-width: 30rem;
  text-align: right;
  p {
    text-align: left;
  }
`

const ErrorMessage = styled.p`
  color: ${theme('colors.error')};
  font-weight: 500;
  font-style: italic;
`

const ButtonRow = styled.div`
  display: flex;
  justify-content: space-between;
`

const SubmitButton = styled(Button)`
  margin: 2rem 0;
`

const ForgotButton = styled(Button)`
  margin: 2rem 0;
  background: transparent;
  text-transform: none;
  color: ${theme('colors.primaryText')};
  font-weight: 300;
  padding: 0;
  margin-left: 0.5rem;
`

const Title = styled.p`
  margin-bottom: 2rem;
`
const Subtitle = styled.p``

const Message = styled.div`
  text-align: left;
`

// const LogoWrapper = styled.div`
//   margin-top: 6rem;
//   display: flex;
//   justify-content: center;
//   img {
//     width: 50%;
//   }
//   margin-bottom: 3rem;
// `

// const BigLogo = ({ src }) => (
//   <LogoWrapper>
//     <img src={src} alt="Sciigo Logo" />
//   </LogoWrapper>
// )

// const getNextLocation = ({ redirect } = {}) =>
//   redirect === undefined ||
//   redirect.includes('login') ||
//   redirect.includes('register')
//     ? '/'
//     : redirect

const sendMagicLink = async email => {
  const { functions } = getFirebase()
  try {
    await functions.httpsCallable('sendMagicLink')({ email })
    window.localStorage.setItem('sciigoMagicLinkEmail', email)
  } catch (e) {
    console.error(e)
    throw new CodeError('auth/unkown', 'Nånting gick fel')
  }
}

const resetPassword = async email => {
  const { functions } = getFirebase()
  try {
    await functions.httpsCallable('sendResetPassword')({ email })
  } catch (e) {
    console.error(e)
    throw new CodeError('auth/unkown', 'Nånting gick fel')
  }
}

export const Login = ({ history, location, title, subtitle, submitLabel }) => {
  const [error, setError] = useState()
  const [forgotPassword, setForgotPassword] = useState()
  const [message, setMessage] = useState('')
  const emailField = useRef()
  useEffect(() => emailField.current?.focus())
  const { auth } = getFirebase()

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
        action: '',
      }}
      onSubmit={async (values, { setSubmitting, resetForm }) => {
        try {
          if (!values.email) {
            setError({ message: 'Email saknas' })
            return
          }
          if (forgotPassword) {
            const { action } = values
            if (action === 'magicLink') {
              await sendMagicLink(values.email)
              setMessage(`En inloggningslänk har skickats till ${values.email}`)
            } else {
              await resetPassword(values.email)
              setMessage(
                `Instruktioner för att återställa lösenordet har skickats till ${values.email}`
              )
            }
            setSubmitting(false)
            setError()
            setForgotPassword(false)
          } else {
            const { email, password } = values
            await auth.signInWithEmailAndPassword(email, password)
            setSubmitting(false)
            setError()
            setForgotPassword(false)
            history.replace('/')
          }
        } catch (e) {
          console.error(e)
          if (
            [
              'auth/wrong-password',
              'auth/no-such-account',
              'auth/user-not-found',
            ].includes(e.code)
          ) {
            setError({ message: 'Felaktig email eller lösenord' })
          } else {
            setError({ message: `Okänt fel: "${e.message}"` })
          }
        }
      }}
    >
      {({ isSubmitting, errors, submitForm, resetForm, values, setValues }) => (
        <Page>
          <StyledForm>
            {/*<BigLogo src="/sciigo.svg" />*/}
            <Title>{title}</Title>
            {subtitle && <Subtitle>{subtitle}</Subtitle>}
            {message ? (
              <>
                <Message>{message}</Message>
                <SubmitButton
                  onClick={e => {
                    setMessage()
                    setError()
                    setForgotPassword(false)
                    resetForm()
                  }}
                >
                  Åter till inloggning
                </SubmitButton>
              </>
            ) : (
              <>
                {error && <ErrorMessage>{error.message}</ErrorMessage>}
                <FormField
                  name="email"
                  type="email"
                  label="Email"
                  tabIndex="1"
                  inputRef={emailField}
                />
                {!forgotPassword && (
                  <FormField
                    name="password"
                    type="password"
                    label="Password"
                    tabIndex="2"
                  />
                )}
                {forgotPassword ? (
                  <ButtonRow>
                    <SubmitButton
                      disabled={isSubmitting || Object.keys(errors).length}
                      onClick={e => {
                        setValues({ ...values, action: 'magicLink' })
                        submitForm()
                      }}
                      tabIndex="3"
                    >
                      Skicka inloggingslänk
                    </SubmitButton>

                    <SubmitButton
                      disabled={isSubmitting || Object.keys(errors).length}
                      type="submit"
                      name="action"
                      value="resetPassword"
                      tabIndex="2"
                    >
                      Återställ lösenord
                    </SubmitButton>
                  </ButtonRow>
                ) : (
                  <ButtonRow>
                    <ForgotButton
                      onClick={e => setForgotPassword(true)}
                      tabIndex="4"
                    >
                      Glömt lösenord?
                    </ForgotButton>
                    <SubmitButton
                      disabled={isSubmitting || Object.keys(errors).length}
                      type="submit"
                      tabIndex="3"
                    >
                      Logga in
                    </SubmitButton>
                  </ButtonRow>
                )}
                {/*                {Object.keys(errors).length > 0 && (
                  <ErrorMessage>{JSON.stringify(errors)}</ErrorMessage>
                )}
*/}{' '}
              </>
            )}
          </StyledForm>
        </Page>
      )}
    </Formik>
  )
}

export default Login
