import { getFirebase, FieldValue } from 'utils/firebase'
import { nanoid } from 'nanoid'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import Page from 'components/Page'
import Loader from 'components/Loader'
import Button from 'components/Button'
import { Link as UnstyledLink, useHistory } from 'react-router-dom'

const Link = styled(UnstyledLink)`
  display: block;
  color: black;
  text-decoration: none;
  font-weight: 300;
  &:hover {
    text-decoration: none;
  }
`

const wordProps = ['target', 'native']

const WordListWrapper = styled.div`
  display: grid;
  grid-template-columns: ${wordProps.map(() => 'auto').join(' ')};
  column-gap: 1rem;
  row-gap: 0.5rem;
  justify-content: start;
  align-items: baseline;
`

const FormWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: span 2;
  padding: 1rem 0;
  form {
    display: grid;
    grid-template-columns: 10rem auto;
    column-gap: 1rem;
    row-gap: 1rem;
    align-items: baseline;
  }
  label {
    font: inherit;
    text-transform: capitalize;
    margin: 0;
  }
  p {
    margin: 0;
  }
`

const ButtonRow = styled.div`
  grid-column-start: 1;
  grid-column-end: span 2;
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 1rem;
`

const WordForm = ({ word, saveWord, close }) => {
  const onSubmit = e => {
    const data = Object.fromEntries(new FormData(e.target))
    saveWord({
      native: data.native,
      target: data.target,
      context: {
        phrase: data.phrase,
        paragraph: data.paragraph,
      },
    })
    e.preventDefault()
    e.stopPropagation()
  }

  const deleteWord = e => {
    saveWord(FieldValue.delete())
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <FormWrapper>
      <form onSubmit={onSubmit}>
        <label>Native</label>
        <input name="native" id="native" defaultValue={word.native} />
        <label>Target</label>
        <input name="target" id="target" defaultValue={word.target} />
        {word.context ? (
          <>
            <label>Phrase</label>
            <p>{word.context.phrase}</p>
            <label>Paragraph</label>
            <p>{word.context.paragraph}</p>
          </>
        ) : !word.id ? (
          <>
            <label>Phrase</label>
            <textarea name="phrase" id="phrase"></textarea>
            <label>Paragraph</label>
            <textarea name="paragraph" id="paragraph"></textarea>
          </>
        ) : (
          <p>No context</p>
        )}
        <ButtonRow>
          <Button onClick={close}>Cancel</Button>
          <Button onClick={deleteWord} disabled={!word.id}>
            Delete
          </Button>
          <Button type="submit">Save</Button>
        </ButtonRow>
      </form>
    </FormWrapper>
  )
}

const WordList = ({ words, deckId, wordId, targetLanguage }) => {
  const history = useHistory()

  const saveWord = word => {
    const { db } = getFirebase()
    const _wordId = wordId === 'new' ? nanoid() : wordId
    db.doc(`decks/${deckId}`).set(
      {
        words: {
          [_wordId]: word,
        },
      },
      { merge: true }
    )
    history.push(`/deck/${deckId}`)
  }

  const items = words
    ? Object.entries(words)
        .map(([id, word]) => ({
          id,
          ...word,
        }))
        .sort((w1, w2) => w1.target.localeCompare(w2.target, targetLanguage))
    : []

  return (
    <WordListWrapper>
      {wordId === 'new' && (
        <WordForm
          word={{}}
          close={() => history.push(`/deck/${deckId}`)}
          saveWord={saveWord}
        />
      )}

      {items.map(word =>
        word.id === wordId ? (
          <WordForm
            key={word.id}
            word={word}
            close={e => history.push(`/deck/${deckId}`)}
            saveWord={saveWord}
          />
        ) : (
          wordProps.map(prop => (
            <Link
              key={`${word.id}.${prop}`}
              to={`/deck/${deckId}/word/${word.id}`}
            >
              {word[prop]}
            </Link>
          ))
        )
      )}
    </WordListWrapper>
  )
}

const languages = {
  en: 'English',
  sv: 'Swedish',
  es: 'Spanish',
  ru: 'Russian',
  de: 'German',
}

const DeckForm = ({ data, close, save, delete: deleteDeck }) => {
  const onSubmit = e => {
    const data = Object.fromEntries(new FormData(e.target))
    save(data)
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <FormWrapper>
      <form onSubmit={onSubmit}>
        <label>Name</label>
        <input name="name" id="name" defaultValue={data.name} />
        <label>Target Language</label>
        <select
          name="targetLanguage"
          id="targetLanguage"
          defaultValue={data.targetLanguage}
        >
          {Object.entries(languages).map(([key, label]) => (
            <option value={key} key={key}>
              {label}
            </option>
          ))}
        </select>
        <label>Native Language</label>
        <select
          name="nativeLanguage"
          id="nativeLanguage"
          defaultValue={data.nativeLanguage}
        >
          {Object.entries(languages).map(([key, label]) => (
            <option value={key} key={key}>
              {label}
            </option>
          ))}
        </select>
        <ButtonRow>
          <Button onClick={close}>Cancel</Button>
          <Button onClick={deleteDeck}>Delete</Button>
          <Button type="submit">Save</Button>
        </ButtonRow>
      </form>
    </FormWrapper>
  )
}

const LinkRow = styled.div`
  margin-top: 1rem;
  margin-bottom: 3rem;
  display: flex;
  align-items: flex-start;
  h1 {
    margin: 0;
    margin-right: 1rem;
  }
`

const ButtonLink = styled(Link)`
  background-color: ${theme('colors.primary')};
  color: ${theme('colors.white')};
  border-radius: 4px;
  font-weight: 500;
  padding: 0.9em 1.6em;
  margin: 0;
  text-transform: uppercase;
  &:hover {
    text-decoration: none;
  }
`

const Deck = ({ match }) => {
  const { deckId, wordId, action } = match.params
  const [state, setState] = useState({ loading: true })
  const history = useHistory()

  useEffect(() => {
    const { db } = getFirebase()
    const unsubscribe = deckId
      ? db.doc(`decks/${deckId}`).onSnapshot(snapshot => {
          setState({
            loading: false,
            deck: snapshot.data(),
          })
        })
      : undefined

    return () => {
      unsubscribe?.()
      //setState({ loading: true })
    }
  }, [deckId])

  const pasteListener = e => {
    const htmlIdx = e.clipboardData.types.indexOf('text/html')
    if (e.target.tagName === 'INPUT' || !e.clipboardData.items[htmlIdx]) {
      e.stopPropagation()
      return
    }
    e.clipboardData.items[htmlIdx].getAsString(htmlString => {
      const parser = new DOMParser()
      const parsed = parser.parseFromString(htmlString, 'text/html')
      const rows = parsed.getElementsByTagName('TR')
      const newWords = Object.fromEntries(
        Array.from(rows).map(row => {
          const [target, _native, context, phrase] = Array.from(
            row.getElementsByTagName('TD')
          ).map(td => td.innerText)
          return [
            nanoid(),
            {
              target,
              native: _native,
              context,
              phrase,
            },
          ]
        })
      )

      if (Object.keys(newWords).length === 0) {
        console.log('No words pasted')
        return
      }

      const { db } = getFirebase()
      db.doc(`decks/${deckId}`).set(
        {
          words: newWords,
        },
        { merge: true, ignoreUndefined: true }
      )
    })
  }

  useEffect(() => {
    window.addEventListener('paste', pasteListener)

    return () => {
      window.removeEventListener('paste', pasteListener)
    }
  })

  const saveDeck = deck => {
    const { db } = getFirebase()
    db.doc(`decks/${deckId}`).set(deck, { merge: true })
    history.push(`/deck/${deckId}`)
  }

  const deleteDeck = () => {
    const { db } = getFirebase()
    db.doc(`decks/${deckId}`).delete()
    history.push(`/`)
  }

  return (
    <Page title={state.deck?.name ?? 'Loading...'}>
      {state.loading ? (
        <Loader />
      ) : (
        <div>
          <LinkRow>
            <Link to={`/deck/${deckId}/edit`}>
              <h1>{state.deck?.name}</h1>
            </Link>
            {!action && <ButtonLink to={`/deck/${deckId}/add`}>Add</ButtonLink>}
          </LinkRow>
          {action === 'edit' && (
            <DeckForm
              data={state.deck}
              save={saveDeck}
              delete={deleteDeck}
              close={e => history.push(`/deck/${deckId}`)}
            />
          )}
          <WordList
            words={state.deck.words}
            deckId={deckId}
            wordId={action === 'add' ? 'new' : wordId}
            targetLanguage={state.deck?.targetLanguage}
          />
        </div>
      )}
    </Page>
  )
}

export default Deck
