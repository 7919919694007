import { useState } from 'react'
import DropTarget from 'components/DropTarget'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { upload } from 'utils/storage'

const Wrapper = styled.div`
  padding: 3rem 3rem 2rem;
`

const StyledDropTarget = styled(DropTarget)`
  position: relative;
`

const ProgressBar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: ${({ progress }) => progress * 100 ?? 0}%;
  background-color: ${theme('colors.primary')};
  bottom: 0;
  z-index: 0;
`

const MessageWrapper = styled.div`
  text-align: center;
  color: ${theme('colors.mediumDark')};
  div:first-child {
    font-weight: 500;
    margin-bottom: 0.5rem;
  }
`

const Message = ({ isdragging, value, isLoading }) => (
  <MessageWrapper>
    {value?.name && <div>{value.name}</div>}
    <div>
      {isdragging
        ? 'Släpp filer för att ladda upp'
        : isLoading
        ? 'Laddar upp fil(er)...'
        : value
        ? `Dra och släpp ny fil för att ersätta`
        : 'Dra och släpp filer här'}
    </div>
  </MessageWrapper>
)

const FileInput = ({
  className,
  name,
  env,
  onUploadStarted,
  onUploadFinished,
  onError,
  ...field
}) => {
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  const { value, onChange } = field

  const onDrop = async files => {
    try {
      setLoading(true)
      onUploadStarted?.()
      const meta = await upload(env.teamId, files[0], {}, setProgress)
      onUploadFinished?.()
      onChange?.({ target: { name, value: meta } })
      setProgress(0)
      setLoading(false)
    } catch (e) {
      // TODO: Translate errors to readable messages
      console.error(e)
      onError?.('Nånting gick fel')
    } finally {
      setLoading(false)
    }
  }

  return (
    <Wrapper>
      <StyledDropTarget className={className} onDrop={onDrop}>
        <ProgressBar progress={progress} />
        <Message isLoading={loading} value={value} />
      </StyledDropTarget>
    </Wrapper>
  )
}

export default FileInput
