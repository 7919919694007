import styled from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import { Link } from 'react-router-dom'
import Icon from './Icon'

const getColor =
  (isBackground = false, backgroundColor = theme('colors.white')) =>
  props => {
    const color = props.theme.colors[props.kind ?? 'primary']
    return isBackground !== (!!props.inverted ?? false)
      ? color
      : backgroundColor
  }

const Button = styled.button.attrs(({ type, ...props }) => ({
  type: type || 'button',
  ...props,
}))`
  margin: 1rem;
  background: ${getColor(true)};
  border: ${ifProp('inverted', '1px solid', 'none')};
  border-color: ${getColor(false)};
  color: ${getColor(false)};
  opacity: ${ifProp('disabled', 0.5, 1)};
  line-height: 1.3;
`

const StyledButton = styled(Button)`
  display: block;
  width: 3rem;
  height: 3rem;
  padding: 0;
  flex-shrink: 0;
`

const StyledLink = styled(Link)`
  display: block;
  width: 3rem;
  height: 3rem;
  margin: 1rem;
  flex-shrink: 0;
  border-radius: 4px;
  text-transform: uppercase;
  cursor: pointer;
  background: ${getColor(true)};
  border: 1px solid;
  border-color: ${getColor(false)};
  color: ${getColor(false)};
  opacity: ${ifProp('disabled', 0.5, 1)};
  display: flex;
  align-items: center;
`

export const IconButton = ({ icon, children, ...props }) => (
  <StyledButton {...props}>
    {icon && <Icon type={icon} />}
    {children}
  </StyledButton>
)

export const NakedButton = styled(IconButton).attrs(() => ({ inverted: true }))`
  margin: 0;
  padding: 0;
  height: auto;
  width: auto;
  border: none;
  text-transform: none;
  font-size: 1rem;
  font-weight: 300;
  svg {
    margin: 0;
    height: 1rem;
    width: 1rem;
  }
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  label {
    font-size: 1rem;
    display: block;
    margin-left: 0.5rem;
    margin-bottom: 0;
  }
`

export const IconLink = ({ icon, ...props }) => (
  <StyledLink {...props}>
    <Icon type={icon} />
  </StyledLink>
)

export default Button
