import React from 'react'
import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'

const Wrapper = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
`

const Input = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`

const CheckMark = styled.span`
  position: relative;
  height: 1rem;
  width: 1rem;
  flex-shrink: 0;
  margin-right: 0.5rem;
  border: 0.5px solid ${theme('colors.border')};

  ${Input}:focus + & {
    box-shadow: ${theme('focusShadow')};
  }

  &:after {
    content: '';
    position: absolute;
    border: solid ${theme('colors.primary')};
    border-width: 0 0 2px 2px;
    width: 70%;
    height: 35%;
    transform: translate(20%, 60%) rotate(-45deg);
    display: ${ifProp('checked', 'block', 'none')};
  }
`

const Label = styled.span`
  font-weight: ${ifProp('checked', '600', '400')};
`

const Checkbox = ({ className, name, label, value, ...props }) => {
  return (
    <Wrapper className={className}>
      <Input type="checkbox" name={name} checked={value} {...props} />
      <CheckMark checked={value} />
      <Label checked={value}>{label}</Label>
    </Wrapper>
  )
}

export default Checkbox
