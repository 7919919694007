import React, { Component, createElement } from 'react'
import ErrorMessage from './ErrorMessage'

class ErrorBoundary extends Component {
  state = {
    error: null,
  }

  static getDerivedStateFromError(error) {
    return { error }
  }

  // componentDidCatch(error, errorInfo) {}

  componentDidUpdate() {
    if (this.state.error) {
      console.log(this.state.error)
    }
  }

  render() {
    if (this.state.error) {
      if (this.props.component) {
        return createElement(this.props.component, this.state)
      } else if (this.props.render) {
        return this.props.render(this.state)
      } else {
        return <ErrorMessage {...this.state} />
      }
    } else {
      return this.props.children
    }
  }
}

export default ErrorBoundary
