import React from 'react'
import { Switch, Route } from 'react-router-dom'
import PrivateRoute from './components/PrivateRoute'
import {
  Dashboard,
  Deck,
  Login,
} from './routes'

function App() {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <PrivateRoute path="/deck/:deckId/word/:wordId" component={Deck} />
      <PrivateRoute path="/deck/:deckId/:action?" component={Deck} />
      <PrivateRoute path="/deck/:deckId" component={Deck} />
      <PrivateRoute path="/" component={Dashboard} />
    </Switch>
  )
}

export default App
