const Sheet = props => (
  <svg viewBox="0 0 1000 1000" {...props}>
    <path
      d="M569.4 147.1V10L41.6 153.7v692.7L569.4 990V852.9h389V147.1h-389zm0 514.5h98.5v85.8h-98.5v-85.8zm0-138.5h98.5v85.8h-98.5v-85.8zm0-138.5h98.5v85.8h-98.5v-85.8zm0-138.6h98.5v85.8h-98.5V246zm160.3 415.6h123.1v85.8H729.7v-85.8zm0-138.5h123.1v85.8H729.7v-85.8zm0-138.5h123.1v85.8H729.7v-85.8zm0-138.6h123.1v85.8H729.7V246zm-485 109.8l64.1 101.3 66.9-107.6 55.4-2.7-93.4 148.4 100.1 159.6-60.2-3-70.5-116.4-71.6 109.5-56.6-2.8L279 495.2l-93.4-136.6 59.1-2.8z"
      fill="currentColor"
    ></path>
  </svg>
)

export default Sheet
