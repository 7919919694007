import styled from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import { isFunction } from 'lodash'
import { useField, useFormikContext } from 'formik'

import Options from './Options'
import Select from './Select'
import Checkbox from './Checkbox'
import TextArea from './TextArea'
import Amount from './Amount'
import FileInput from './FileInput'

const Wrapper = styled.div`
  position: relative;
  & + & {
    margin-top: 0.5rem;
  }
`

const Description = styled.p`
  font-style: italic;
  font-size: 0.8em;
`

const Label = styled.label`
  font-size: 1rem;
  line-height: 1.3;
  *:not(sup):not(sub) {
    font-size: 1rem;
  }
  > ${Description} {
    font-size: 0.9em;
  }
  padding-left: 0.5rem;
`

const Legend = styled.legend`
  font-size: 1.2rem;
  margin-bottom: 0.6rem;
`

const ErrorMessage = styled.p`
  padding-left: 0.5rem;
  font-size: 0.8rem;
  line-height: 1rem;
  height: 1rem;
  text-align: left;
  margin: 0.3rem 0;
  color: ${ifProp('touched', theme('colors.error'), '')};
`

export const FieldSet = styled.fieldset`
  border: none;
  padding: 0.7rem;
  margin: 0;
  border: 1px solid #eee;
  border-radius: 4px;

  ${Label} {
    font-size: 1rem;
  }
`

const StyledInput = styled.input`
  &[disabled] {
    background-color: transparent;
  }
  ::placeholder {
    color: ${theme('colors.medium')};
  }
`

const FormField = ({
  className,
  inputRef,
  label,
  unit,
  description,
  name,
  type,
  multiple,
  placeholder,
  disabled,
  options,
  labels,
  isCreatable,
  fields,
  env,
  tabIndex,
  validate,
}) => {
  const { setSubmitting } = useFormikContext()
  const [field, meta, { setError, setTouched }] = useField({
    name,
    validate: false,
  })

  if (type === 'section') {
    return (
      <Section {...{ name, field, label, unit, fields, disabled, className }} />
    )
  }

  return (
    <Wrapper className={className}>
      {label && type !== 'checkbox' && (
        <Label htmlFor={name}>
          <p>{label}</p>
        </Label>
      )}
      {type === 'options' ? (
        <Options
          options={options}
          {...field}
          isMulti={Array.isArray(meta.initialValue) || multiple}
          disabled={disabled}
        />
      ) : type === 'select' ? (
        <Select
          options={options}
          {...field}
          isCreatable={isCreatable}
          isMulti={Array.isArray(meta.initialValue) || multiple}
          disabled={disabled}
          ref={inputRef}
        />
      ) : type === 'checkbox' ? (
        <Checkbox
          name={name}
          label={label ?? ''}
          disabled={disabled}
          ref={inputRef}
          {...field}
        />
      ) : type === 'textArea' ? (
        <TextArea
          name={name}
          disabled={disabled}
          {...field}
          tabIndex={tabIndex}
          ref={inputRef}
        />
      ) : type === 'amount' ? (
        <Amount name={name} options={options} disabled={disabled} />
      ) : type === 'boolean' ? (
        <Options
          options={[
            { value: true, label: labels?.[0] ?? 'Ja' },
            { value: false, label: labels?.[1] ?? 'Nej' },
          ]}
          disabled={disabled}
          {...field}
        />
      ) : type === 'file' ? (
        <FileInput
          env={env}
          disabled={disabled}
          onUploadStarted={() => setSubmitting(true)}
          onUploadFinished={() => {
            setTouched(true)
            setSubmitting(false)
          }}
          onError={e => {
            setTouched(true)
            setSubmitting(false)
            setError(e)
          }}
          {...field}
        />
      ) : (
        <StyledInput
          type={type}
          pattern={['int', 'year'].includes(type) ? '\\d*' : null}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          ref={inputRef}
          tabIndex={tabIndex}
          {...field}
        />
      )}
      {type !== 'hidden' && (
        <ErrorMessage touched={meta.touched}>{meta.error}</ErrorMessage>
      )}
    </Wrapper>
  )
}

const StyledFormField = styled(FormField)``
export default StyledFormField


const getItemVisible = (item, value) =>
  isFunction(item.visible) ? item.visible(value) : item.visible !== false

const getItemOptions = (item, value) =>
  isFunction(item.options) ? item.options(value) : item.options

const Section = ({ name, field, label, unit, fields, disabled, className }) => (
  <Wrapper className={className}>
    {label && (
      <Legend>
        {label}
      </Legend>
    )}
    <FieldSet>
      {fields &&
        Object.entries(fields).map(([key, item]) =>
          getItemVisible(item, field.value) ? (
            <FormField
              className={className}
              key={key}
              label={item.label}
              placeholder={item.placeholder}
              unit={item.unit}
              name={`${name}.${key}`}
              type={item.type}
              description={item.description}
              options={getItemOptions(item, field.value)}
              disabled={disabled}
              validate={item.validate}
            />
          ) : null
        )}
    </FieldSet>
  </Wrapper>
)


