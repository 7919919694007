import { useEffect, useState } from 'react'
import { getFirebase } from 'utils/firebase'
import { nanoid } from 'nanoid'
import { Link as UnstyledLink } from 'react-router-dom'
import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'
import { NakedButton } from 'components/Button'

const MenuWrapper = styled.menu`
  width: 25vw;
  min-width: 18rem;
  max-width: 25rem;
  background: white;
  flex-shrink: 0;
  margin: 0;
  padding: 0;
  text-align: left;
  overflow-y: auto;
  h2 {
    position: relative;
    font-size: 1rem;
    font-weight: 500;
    padding: 0.65rem 0.65rem 0.45rem;
    background: ${theme('colors.primaryMediumLight')};
    text-transform: uppercase;
    letter-spacing: 0.04em;
    color: white;
    margin: 0;
  }
`

const Link = styled(UnstyledLink)`
  display: block;
  color: ${theme('colors.primaryText')};
  text-transform: ${ifProp('raw', 'none', 'capitalize')};
  border-bottom: 1px solid ${theme('colors.border')};
  padding: 0.65rem 1.3rem;
  font-weight: 400;
  &:hover {
    text-decoration: none;
    background: ${theme('colors.primaryThin')};
  }
`

const NewDeckButton = styled(NakedButton)`
  background-color: transparent;
  position: absolute;
  top: 0.1rem;
  right: 0.1rem;
  color: white;
  > svg {
    margin-right: 0.2rem;
  }
`

const Menu = props => {
  const [state, setState] = useState({ loading: true })

  useEffect(() => {
    const { db } = getFirebase()
    db.collection('decks')
      .get()
      .then(snapshot => {
        const data = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }))
        setState({
          loading: false,
          decks: data,
        })
      })
  })

  const addDeck = async () => {
    const { db } = getFirebase()
    const newDeckId = nanoid()
    await db.doc(`decks/${newDeckId}`).set({
      name: 'New Deck',
      targetLanguage: 'en',
      nativeLanguage: 'sv',
      words: {},
    })
  }

  return (
    <MenuWrapper>
      <Link to="/">Home</Link>
      <h2>
        Decks
        <NewDeckButton icon="plus" onClick={addDeck}>
          New deck
        </NewDeckButton>
      </h2>
      {state.loading ? (
        <p>...</p>
      ) : (
        state.decks.map(deck => (
          <Link key={deck.id} to={`/deck/${deck.id}`}>
            {deck.name}
          </Link>
        ))
      )}
    </MenuWrapper>
  )
}

export default Menu
