import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { HelmetProvider } from 'react-helmet-async'
import { theme, GlobalStyle } from 'theme'
import { AuthProvider } from 'providers/Session'
import ErrorBoundary from 'components/ErrorBoundary'

const Root = ({ children }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      <ErrorBoundary>
        <HelmetProvider>
          <AuthProvider>
            <Router>{children}</Router>
          </AuthProvider>
        </HelmetProvider>
      </ErrorBoundary>
    </>
  </ThemeProvider>
)

export default Root
