import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import Icon from './Icon'

const Wrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
const Title = styled.h2`
  font-size: 2.2em;
  margin-bottom: 0.3em;
`
const Message = styled.p`
  font-size: 1em;
`

const ErrorIcon = styled(Icon).attrs(() => ({
  type: 'warning',
}))`
  display: block;
  width: 5rem;
  height: 5rem;
  margin: 0.6em 0 0.15em;
  color: ${theme('colors.error')};
`

const ErrorMessage = ({ className }) => (
  <Wrapper className={className}>
    <ErrorIcon />
    <Title>Nåt gick fel</Title>
    <Message>Försök igen senare</Message>
  </Wrapper>
)

export default ErrorMessage
