import { createContext, useEffect, useState } from 'react'
import { getFirebase } from 'utils/firebase'

export const AuthContext = createContext({
  loading: true,
})

export const AuthProvider = ({ children }) => {
  const { auth } = getFirebase()

  const [state, setState] = useState({
    user: auth.currentUser,
    loading: true,
  })

  useEffect(
    () =>
      auth.onAuthStateChanged(async user => {
        if (user) {
          setState({
            user: {
              displayName: user.displayName,
              email: user.email,
              uid: user.uid,
            },
            loading: false,
          })
        } else if (auth.isSignInWithEmailLink(window.location.href)) {
          try {
            const email = window.localStorage.getItem('sciigoMagicLinkEmail')
            if (email) {
              const { user } = await auth.signInWithEmailLink(
                email,
                window.location.href
              )
              window.localStorage.removeItem('sciigoMagicLinkEmail')
              setState({
                user: {
                  displayName: user.displayName,
                  email: user.email,
                  uid: user.uid,
                },
                loading: false,
              })
            } else {
              console.log('Email is missing')
              setState({
                user: null,
                loading: false,
                emailMissingForMagicLinkSignIn: true,
              })
            }
          } catch (e) {
            console.error(e)
            setState({
              user: null,
              loading: false,
              error: e,
            })
          }
        } else {
          setState({
            user: null,
            loading: false,
          })
        }
      }),
    [auth]
  )

  const logout = () =>
    auth
      .signOut()
      .then(() => {
        console.log('Signed out')
      })
      .catch(e => {
        console.error(e)
      })

  return (
    <AuthContext.Provider
      value={{
        ...state,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
