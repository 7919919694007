import { useState, useMemo } from 'react'
import styled from 'styled-components'
import Select from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { theme, withProp } from 'styled-tools'
import { tint } from 'polished'

const StyledSelect = styled(Select).attrs({
  classNamePrefix: 'react-select',
})`
  .react-select__ {
    &control {
      min-height: 2.3rem;
      font-weight: 500;
      border-radius: 4px;
      &--is-disabled {
        background-color: transparent;
        color: ${theme('colors.primaryText')};
      }
    }
    &input {
      input:focus {
        box-shadow: none;
      }
    }
    &indicator-separator {
      display: none;
    }
    &clear-indicator,
    &dropdown-indicator {
      display: flex;
      align-items: center;
      padding: 0 0.25rem;
      width: 2rem;
    }
    &value-container {
      padding: 0 0.5rem;
      &--is-multi {
        padding: 0 0.25rem;
      }
    }
    &single-value {
      color: ${theme('colors.primaryText')};
      padding: 0.1rem 0;
      transition: opacity 300ms;
      &--is-disabled {
        opacity: 0.7;
      }
    }
    &multi-value {
      padding: 0.25rem;
      background: ${theme('colors.primary')};
      color: ${theme('colors.white')};
      align-items: center;
      &__label {
        background: transparent;
        color: inherit;
        font-size: 100%;
        padding: 0.25rem;
      }
      &__remove {
        cursor: pointer;
        width: 1rem;
        height: 1rem;
        padding: 0;
        margin-top: 0.1rem;
        &:hover {
          background: transparent;
          color: inherit;
        }
      }
    }
    &menu {
      font-weight: 500;
      border-radius: 1px;
      max-height: 18rem;
      overflow-y: auto;
    }
    &menu-list {
      padding: 0;
      border: none;
      max-height: 18rem;
      z-index: 13;
    }
    &option {
      text-align: left;
      background: ${theme('colors.white')};
      color: ${theme('colors.primaryText')};
      border-bottom: 1px solid ${theme('colors.border')};
      height: 2.3rem;
      line-height: 2.3rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 1rem;
      &--is-selected {
        background: ${withProp('theme.colors.primary', tint(0.8))};
      }
      &--is-focused {
        background: ${theme('colors.primary')};
        color: ${theme('colors.white')};
      }
    }
  }
`

const getValue = (item, isMulti) =>
  isMulti ? (item || []).map(({ value }) => value) : (item || {}).value

const findOption = options => v =>
  options ? options.find(({ value }) => value === v) : null

const getSelected = (value, options, isMulti) =>
  options?.length === 1
    ? options[0]
    : isMulti
    ? value.map(findOption(options))
    : findOption(options)(value) ?? null

const FormikSelect = ({
  className,
  options: initialOptions,
  disabled,
  name,
  isMulti,
  isSearchable,
  isCreatable,
  ...field
}) => {
  const [tempOptions, setTempOptions] = useState([])
  const options = useMemo(
    () => [...(initialOptions ?? []), ...tempOptions],
    [initialOptions, tempOptions]
  )

  const onChange = (item, { action, option }) => {
    if (action === 'create-option') {
      setTempOptions(tempOptions => [...tempOptions, option])
    }
    field.onChange({ target: { name, value: getValue(item, isMulti) } })
  }

  return (
    <StyledSelect
      as={isCreatable ? CreatableSelect : Select}
      isDisabled={disabled}
      isMulti={isMulti}
      className={className}
      options={options}
      value={getSelected(field.value, options, isMulti)}
      onChange={onChange}
      onBlur={() => (field.onBlur ? field.onBlur({ target: { name } }) : null)}
      isSearchable={isSearchable}
    />
  )
}

export default FormikSelect
