import { useState } from 'react'
import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'
import { addPropsToChildren } from 'utils/react'

const DropTargetWrapper = styled.div`
  border: 1px solid ${theme('colors.border')};
  padding: 2rem;
  box-sizing: border-box;
  border: 1px dashed
    ${ifProp(
      'isdragging',
      theme('colors.primary'),
      theme('colors.primaryMediumLight')
    )};
  background-color: ${ifProp(
    'isdragging',
    theme('colors.border'),
    theme('colors.background')
  )};
  border-radius: 3px;
  div {
    pointer-events: none !important;
  }
`

const DropTarget = ({ onDrop, children, className }) => {
  const [isDragging, setIsDragging] = useState(false)

  const onDragOver = e => {
    e.preventDefault()
  }

  const _onDrop = e => {
    e.preventDefault()
    setIsDragging(false)
    const files = e.dataTransfer.files
    if (files.length > 0) {
      onDrop(files)
    } else {
      console.error('Can only drop files')
    }
  }

  const onDragEnter = e => {
    e.preventDefault()
    if (e.dataTransfer.types.filter(type => type.includes('file')).length > 0) {
      setIsDragging(true)
    }
  }

  const onDragLeave = e => {
    setIsDragging(false)
  }

  const childrenWithIsDragging = addPropsToChildren(
    children,
    { isdragging: isDragging ? 'true' : undefined },
    false
  )

  return (
    <DropTargetWrapper
      className={className}
      onDrop={_onDrop}
      onDragOver={onDragOver}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      isdragging={isDragging}
    >
      {childrenWithIsDragging}
    </DropTargetWrapper>
  )
}

export default DropTarget
