const Warning = props => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <g fill="none">
      <path
        d="M21.266 20.998H2.733a1 1 0 0 1-.866-1.5l9.266-16a1 1 0 0 1 1.73 0l9.267 16a1 1 0 0 1-.865 1.5zm-10.266-5v2h1.998v-2H11zm0-7v5h2v-5h-2z"
        fill="currentColor"
      ></path>
    </g>
  </svg>
)

export default Warning
