import { useEffect } from 'react'
import styled from 'styled-components'
import Select from './Select'
import { useField } from 'formik'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
`

const StyledSelect = styled(Select)`
  flex: 1;
  flex-basis: 40%;
  flex-shrink: 0;
  min-width: 6rem;
  && .react-select__control {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
`

const StyledInput = styled.input`
  flex: 1;
  flex-basis: 60%;
  border-right: none;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  &[disabled] {
    background-color: transparent;
  }
`

const Amount = ({
  className,
  label,
  name,
  placeholder,
  disabled,
  options,
  value,
}) => {
  const [field] = useField(`${name}.amount`)
  const [unitField, , { setValue: setUnitValue }] = useField(`${name}.unit`)
  useEffect(() => {
    if (options?.length === 1 && unitField?.value !== options[0].value) {
      setUnitValue(options[0].value)
    } else if (
      unitField?.value !== undefined &&
      !options?.find(option => option.value === unitField.value)
    ) {
      setUnitValue(undefined)
    }
  }, [unitField, options, setUnitValue])
  return (
    <Wrapper>
      <StyledInput
        type="number"
        name={`${name}.amount`}
        placeholder={placeholder}
        disabled={disabled}
        {...field}
      />
      <StyledSelect
        disabled={options?.length === 1 || disabled}
        isSearchable={options?.length > 5}
        name={`${name}.unit`}
        options={options}
        {...unitField}
      />
    </Wrapper>
  )
}

export default Amount
