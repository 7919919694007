import React, { useEffect } from 'react'
import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
`

const Capsule = styled.button.attrs(() => ({
  type: 'button',
}))`
  display: flex;
  width: 100%;
  align-items: center;
  text-transform: none;

  border: 1px solid;
  border-radius: 100px;
  border-color: ${ifProp(
    'active',
    theme('colors.primary'),
    theme('colors.border')
  )};
  background: ${ifProp('active', theme('colors.primary'), 'white')};
  &[disabled] {
    border-color: ${ifProp(
      'active',
      theme('colors.medium'),
      theme('colors.border')
    )};
    background: ${ifProp('active', theme('colors.medium'), 'white')};
  }
  color: ${ifProp(
    'active',
    theme('colors.white'),
    theme('colors.primaryText')
  )};
  font-weight: ${ifProp('active', 600, 400)};

  padding: 0.5rem 0.6rem;
  margin: 0.3rem 0;
`

const Label = styled.span`
  margin: 0 0.5rem;
  color: inherit;
`

const Options = ({ className, options, name, isMulti, disabled, ...field }) => {
  const { value, onChange } = field
  useEffect(() => {
    if (options.length === 1 && value !== options[0].value) {
      onChange({
        target: {
          name,
          value: isMulti ? [options[0].value] : options[0].value,
        },
      })
    } else if (
      !isMulti &&
      value != null &&
      !options?.find(option => option.value === value)
    ) {
      onChange({ target: { name, value: isMulti ? [] : null } })
    }
  }, [value, onChange, name, options, isMulti])
  const isActive = value =>
    isMulti ? field.value.includes(value) : field.value === value
  const getNewValue = value => {
    if (!isMulti) {
      return value
    }
    return field.value.includes(value)
      ? field.value.filter(v => v !== value)
      : field.value.concat(value)
  }
  return (
    <Wrapper className={className}>
      {options.map(({ value, label }) => (
        <Capsule
          key={value}
          active={isActive(value)}
          disabled={disabled}
          onClick={() =>
            field.onChange({ target: { name, value: getNewValue(value) } })
          }
        >
          <Label>{label}</Label>
        </Capsule>
      ))}
    </Wrapper>
  )
}
export default Options
