import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/functions'
import 'firebase/storage'
import 'firebase/auth'
import config from 'config'

let _firebase
const getFirebase = () => {
  if (_firebase) {
    return _firebase
  } else if (config && firebase.apps.length === 0) {
    console.log('Initializing firebase')
    const app = firebase.initializeApp(config.firebase)
    const db = app.firestore()
    db.settings({ ignoreUndefinedProperties: true })
    const auth = app.auth()
    const functions = app.functions(config.functions.region)
    const storage = app.storage(config.firebase.documentBucket)
    if (config.useEmulators) {
      db.useEmulator('127.0.0.1', 8080)
      auth.useEmulator('http://127.0.0.1:9099')
      functions.useFunctionsEmulator('http://127.0.0.1:5001')
      storage.useEmulator('127.0.0.1', 9199)
    }
    _firebase = { db, auth, functions, storage }
    return _firebase
  } else {
    console.log('Firebase already initialized')
    const app = firebase.app()
    return (_firebase = {
      db: app.firestore(),
      auth: app.auth(),
      storage: app.storage(config.documentBucket),
      functions: app.functions('europe-west1'),
    })
  }
}

const FieldValue = firebase.firestore.FieldValue
const FieldPath = firebase.firestore.FieldPath
const Timestamp = firebase.firestore.Timestamp
const TaskState = firebase.storage.TaskState

const backendCall = (path, data, args) => {
  const { functions } = getFirebase()
  const fn = functions.httpsCallable(path, args)
  return fn(data)
}

const dateFromTimestamp = t =>
  !t
    ? undefined
    : t.toDate
    ? t.toDate()
    : new Timestamp(t._seconds, t._nanoseconds).toDate()

const toTimestamp = inputString => {
  if (!inputString) {
    return undefined
  }
  const datestr = inputString.replaceAll('-', '')
  return Timestamp.fromDate(
    new Date(
      datestr.slice(0, 4),
      parseFloat(datestr.slice(4, 6)) - 1,
      datestr.slice(6)
    )
  )
}

const delegates = {
  'FieldValue.serverTimestamp': FieldValue.serverTimestamp(),
  'FieldValue.delete': FieldValue.delete(),
}

const cleanData = (data, undefinedMeansDelete) =>
  JSON.parse(
    JSON.stringify(data, (key, value) =>
      value === undefined
        ? undefinedMeansDelete
          ? { _delegate: { _methodName: 'FieldValue.delete' } }
          : undefined
        : value === null
        ? undefined
        : value
    ),
    (key, value) =>
      value._delegate
        ? delegates[value._delegate._methodName]
        : value.seconds !== undefined && value.nanoseconds !== undefined
        ? new Timestamp(value.seconds, value.nanoseconds)
        : value
  )

export {
  FieldValue,
  Timestamp,
  FieldPath,
  toTimestamp,
  TaskState,
  dateFromTimestamp,
  cleanData,
  getFirebase,
  backendCall,
}
