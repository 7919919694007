export class ApiError extends Error {
  constructor(message, data, ...params) {
    super(message, ...params)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError)
    }
    this.name = 'ApiError'
    this.data = data
  }
}

export class CodeError extends Error {
  constructor(code, ...params) {
    super(...params)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, CodeError)
    }
    this.name = 'CodeError'
    this.code = code
  }
}
